import React from "react";
import appStoreBadge from "../images/download-on-the-app-store-badge.png";
import googlePlayBadge from "../images/google-play-badge.png";
import mixpanel from "mixpanel-browser";

const Navbar = () => {
  return (
    <>
      <header>
        <nav>
          <div className="left-logo">
            <a className="logo" href="/">
              <img
                src="https://static.wixstatic.com/media/109a05_e29d749f0ba04d789b58951be536744c~mv2.png/v1/fill/w_44,h_44,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logo.png"
                alt="logo"
              />
              <span>RHRN</span>
            </a>
            <span>the new age dating app</span>
          </div>
          <div className="store-badges">
            <a
              href="https://apps.apple.com/in/app/rhrn-right-here-right-now/id1489170650"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                mixpanel.track("Clicked: Download App from Apple App Store")
              }
            >
              <img
                src={appStoreBadge}
                height="51px"
                alt="download on the app store badge"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=joyage.downtown.dating"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                mixpanel.track("Clicked: Download App from Google Play Store")
              }
            >
              <img
                id="google-play-store"
                src={googlePlayBadge}
                height="74px"
                alt="get it on google play badge"
              />
            </a>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
