import "./App.scss";
import Navbar from "./components/Navbar";
import Sections from "./components/Sections";
import heroImage from "./images/hero-img.webp";
import gif from "./images/file.mp4";
import image2 from "./images/12.png";
import image3 from "./images/14.png";
import FeaturesTable from "./components/FeaturesTable";
import { useState } from "react";
import mixpanel from "mixpanel-browser";
import { path as apiBaseURL } from "./utils/Api";

const QUERYFORM_VALIDATION_SUCCESS_MESSAGE = "Thanks for Submitting!!";
const QUERYFORM_VALIDATION_ERROR_MESSAGE =
  "Something went wrong while submitting form. Please try again after sometime.";
const SUBSCRIBEFORM_VALIDATION_SUCCESS_MESSAGE = "Subscribed successfully!";
const SUBSCRIBEFORM_VALIDATION_ERROR_MESSAGE =
  "Something went wrong while submitting form. Please try again after sometime.";

const RHRN_API_URL = apiBaseURL();

function App() {
  const [queryFormEmail, setQueryFormEmail] = useState("");
  const [queryFormName, setQueryFormName] = useState("");
  const [queryFormMessage, setQueryFormMessage] = useState("");
  const [queryFormValidationMessage, setQueryFormValidationMessage] =
    useState("");
  const [subscribeFormValidationMessage, setSubscribeFormValidationMessage] =
    useState("");

  const [subscribeFormEmail, setSubscribeFormEmail] = useState("");

  const displayMessage = (message, setMessage) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const handleQueryFormSubmit = (event) => {
    event.preventDefault();

    const data = {
      name: queryFormName,
      email: queryFormEmail,
      message: queryFormMessage,
    };

    if (queryFormName && queryFormEmail && queryFormMessage) {
      fetch(RHRN_API_URL + "web/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          // mixpanel.identify(queryFormEmail);
          mixpanel.track("query Submitted", {
            "User Name": queryFormName,
            "User Email": queryFormEmail,
            "User Message": queryFormMessage,
          });
          displayMessage(
            QUERYFORM_VALIDATION_SUCCESS_MESSAGE,
            setQueryFormValidationMessage
          );
        })
        .catch((error) =>
          displayMessage(
            QUERYFORM_VALIDATION_ERROR_MESSAGE,
            setQueryFormValidationMessage
          )
        );
    } else {
      displayMessage(
        "All the fields are mandatory! Pls try again.",
        setQueryFormValidationMessage
      );
    }
  };

  const handleSubscribeFormSubmit = (event) => {
    event.preventDefault();

    const data = {
      email: subscribeFormEmail,
    };

    if (subscribeFormEmail) {
      fetch(RHRN_API_URL + "web/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          // mixpanel.identify(subscribeFormEmail);
          mixpanel.track("User Subscribed", {
            "User email": subscribeFormEmail,
          });
          displayMessage(
            SUBSCRIBEFORM_VALIDATION_SUCCESS_MESSAGE,
            setSubscribeFormValidationMessage
          );
        })
        .catch((error) =>
          displayMessage(
            SUBSCRIBEFORM_VALIDATION_ERROR_MESSAGE,
            setSubscribeFormValidationMessage
          )
        );
    } else {
      displayMessage(
        "Please enter valid email id!",
        setSubscribeFormValidationMessage
      );
    }
  };
  return (
    <>
      <Navbar />
      {/* || HERO || */}
      <div className="App">
        <div className="hero">
          <img
            className="hero-image"
            src={heroImage}
            alt="hero: man and woman talking and laughing on date"
          />
          <h1>Right Here Right Now</h1>
        </div>
        {/* || SECTIONS 1 - 3 */}
        <Sections
          image={gif}
          text="Move over photos, browse "
          underlineText="Video Profiles"
          containerColor="#ff8b70"
        />
        <Sections
          image={image2}
          text="Send interest. Receive response in just "
          underlineText="60 seconds"
          containerColor="#43b1d1"
        />
        <Sections
          image={image3}
          text="No more waiting. Meet your match in just "
          underlineText="60 minutes"
          containerColor="#af72b7"
        />
        <FeaturesTable />
        <section className="forms">
          <div className="query-form">
            <h1 className="heading">Ask Us Anything!</h1>
            <form onSubmit={handleQueryFormSubmit}>
              <div className="inputs">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  onChange={(event) => setQueryFormName(event.target.value)}
                />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={(event) => setQueryFormEmail(event.target.value)}
                />
              </div>
              <textarea
                name="message"
                id="message"
                rows="5"
                placeholder="Type your message"
                onChange={(event) => setQueryFormMessage(event.target.value)}
              ></textarea>
              <button type="submit">Submit</button>
              <div className="validationMessage">
                {queryFormValidationMessage}
              </div>
            </form>
          </div>
          <div className="subscribe-form">
            <h1>Subscribe for News, Updates, Promotions and More!</h1>
            <div className="social-logo">
              <a
                href="https://www.facebook.com/RightHereRightNowOfficial"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://static.wixstatic.com/media/4057345bcf57474b96976284050c00df.png/v1/fill/w_32,h_32,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4057345bcf57474b96976284050c00df.png"
                  alt="fb logo"
                />
              </a>
              <a
                href="https://instagram.com/rhrn_dating?igshid=k0dysgufob2h"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://static.wixstatic.com/media/e1aa082f7c0747168d9cf43e77046142.png/v1/fill/w_32,h_32,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e1aa082f7c0747168d9cf43e77046142.png"
                  alt="instagram logo"
                />
              </a>
            </div>
            <form onSubmit={handleSubscribeFormSubmit}>
              <input
                type="text"
                placeholder="Enter your email here"
                onChange={(event) => setSubscribeFormEmail(event.target.value)}
              />
              <button type="submit">Subscribe Now</button>
              <div className="validationMessage">
                {subscribeFormValidationMessage}
              </div>
            </form>
          </div>
        </section>
        <footer>
          <div className="contact-links">
            <a
              href="mailto:admin@rhrn.app?subject=RHRN | Help"
              target="_self"
              onClick={() => mixpanel.track("Clicked: Contact us")}
            >
              Contact Us
            </a>
            <br />
            <a
              href="mailto:admin@rhrn.app"
              onClick={() => mixpanel.track("Clicked: Contact us")}
            >
              admin@rhrn.app
            </a>
            {/* <a href="mailto:contact@joyage.in">contact@joyage.in</a> */}
          </div>
          <a
            className="footer"
            href="https://www.joyage.in/"
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              mixpanel.track("Clicked on 'https://joyage.in' footer")
            }
          >
            Developed By : Joyage Online Solutions Pvt Ltd
          </a>
        </footer>
      </div>
    </>
  );
}

export default App;
