import React from "react";

const FeaturesTable = () => {
  return (
    <section className="app-features">
      <h1 style={{ backgroundColor: "#ECF1E5" }}>A dating app you'll love</h1>
      <div className="feature-table">
        <div
          className="feature-row"
          style={{ backgroundColor: "rgb(247 181 0 / 70%)" }}
        >
          <h2 className="row-heading">Safe</h2>
          <div className="row-text">
            <p>
              Extensive screening is being done to eliminate phishing,
              soliciting and fake profiles.
            </p>
            <p>
              Meetings scheduled on RHRN will only take place in public places
              like cafe.
            </p>
          </div>
        </div>
        <div
          className="feature-row"
          style={{ backgroundColor: "rgb(67 133 245 / 70%)" }}
        >
          <h2 className="row-heading">Secure</h2>
          <div className="row-text">
            <p>
              Your contact information will never be shared with anyone without
              your permission.
            </p>
            <p>Your location details will never be shared with anyone.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesTable;
