import axios from "axios";
let url = "";
let statucurl = "";

if (process.env.REACT_APP_ENV === "production") {
  url = "https://api.therhrn.com/";
  statucurl = "https://api.therhrn.com/";
} else if (process.env.REACT_APP_ENV === "development") {
  url = "http://dev.therhrn.com/";
  statucurl = "http://dev.therhrn.com/";
} else {
  url = "http://localhost:5000/";
  statucurl = "http://localhost:5000";
  //For temporary usage
  url = "http://dev.therhrn.com/";
  statucurl = "http://dev.therhrn.com/";
}

export const path = () => {
  return statucurl;
};
export default axios.create({
  baseURL: url,
});
