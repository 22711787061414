import mixpanel from "mixpanel-browser";
import { REACT_APP_MIXPANEL_TOKEN, REACT_APP_NODE_ENV } from "../config";
import { EVENTS_NAMES } from "../constants/mixPanelConstants";

let MIXPANEL_ACTIVE = REACT_APP_NODE_ENV === "production";

mixpanel.init(REACT_APP_MIXPANEL_TOKEN, {
  debug: !MIXPANEL_ACTIVE,
  ignore_dnt: true,
});

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => {
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;

export function onPageLoad() {
  actions.track(EVENTS_NAMES.USER_VISIT);
}
