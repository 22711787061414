import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Mixpanel, onPageLoad } from "./utils/Mixpanel";
import mixpanel from "mixpanel-browser";

const root = ReactDOM.createRoot(document.getElementById("root"));

onPageLoad();

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
