import React from "react";

const Sections = (props) => {
  return (
    <section>
      <div className="app-description-sections">
        <div className="image">
          {props.image.includes(".mp4") ? (
            <video
              src={props.image}
              playsInline
              muted
              preload="auto"
              autoPlay
              loop></video>
          ) : (
            <img src={props.image} alt="check" />
          )}
        </div>
        <div className="text" style={{ backgroundColor: props.containerColor }}>
          <div>
            {props.text}
            <span style={{ textDecoration: "underline" }}>
              {props.underlineText}
            </span>
            .
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sections;
